import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { formatAMPM, formatDate, formatDateAndTime, serverLink, TimeFormat } from "../url";

const ManageTracks = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [speakersList, setspeakersList] = useState([]);
    const [pList2, setpList2] = useState([]);
    const [sessionList, setsessionList] = useState([]);

    const [speakerSessions, setSpeakerSessions] = useState([])
    const [spkTrack, setspkTrack] = useState([])
    const [spkSession, setspkSession] = useState([])

    const getData = async () => {
        let spk_list = [];
        let spk_session = []
        let spk_track = []
        try {
            await axios.get(`${serverLink}session/list/session`).then((res) => {
                if (res.data.length > 0) {
                    setsessionList(res.data)
                }
            })
            await axios.get(`${serverLink}speakers/admin-speakers/list`).then((res) => {
                if (res.data.length > 0) {
                    spk_list = res.data;
                    setspeakersList(res.data);
                    setpList2(res.data);
                }
            })


            await axios.get(`${serverLink}speakers/admin-speakers/speaker-session/list`).then((res) => {
                if (res.data.length > 0) {
                    spk_session = res.data;
                    setspkSession(res.data)
                }

            })

            await axios.get(`${serverLink}speakers/admin-speakers/speaker-track/list`).then((res) => {
                if (res.data.length > 0) {
                    spk_track = res.data;
                    setspkTrack(res.data)
                }

            })

            let data_ = [];
            spk_list.map((x, y) => {
                data_.push({
                    EntryID: x.EntryID,
                    FirstName: x.FirstName,
                    MiddleName: x.MiddleName,
                    LastName: x.LastName,
                    Designation: x.Designation,
                    Organization: x.Organization,
                    Title: x.Title,
                    Email: x.Email,
                    Phone: x.Phone,
                    ss: spk_track.filter(j => j.SpeakerID === x.EntryID),
                    s_sess: spk_session.filter(j => j.SpeakerID === x.EntryID),

                })
            })
            setSpeakerSessions(data_);
            setIsLoading(false)
        } catch (e) {

        }
    }

    const onSearch = (e) => {
        let data_ = [];
        let filtered = pList2.filter(x =>
            x.FirstName !== null && x.FirstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.MiddleName !== null && x.MiddleName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.LastName !== null && x.LastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Email !== null && x.Email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.Phone !== null && x.Phone.includes(e.target.value) ||
            x.Designation !== null && x.Designation.toLowerCase().includes(e.target.value) ||
            x.Organization !== null && x.Organization.toLowerCase().includes(e.target.value)
        );
        filtered.map((x, y) => {
            data_.push({
                EntryID: x.EntryID,
                FirstName: x.FirstName,
                MiddleName: x.MiddleName,
                LastName: x.LastName,
                Designation: x.Designation,
                Organization: x.Organization,
                Title: x.Title,
                Email: x.Email,
                Phone: x.Phone,
                ss: spkTrack.filter(j => j.SpeakerID === x.EntryID),
                s_sess: spkSession.filter(j => j.SpeakerID === x.EntryID),

            })
        })
        setSpeakerSessions(data_);
    }

    useEffect(() => {
        getData();
    }, [])


    return isloading ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Tracks"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title">Speakers</h5>

                                    <input type={"text"} style={{ height: '30px', borderRadius: '5px' }} placeholder="search" onChange={onSearch} />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">FullName</th>
                                                <th scope="col">Organization</th>
                                                <th scope="col">Designation</th>
                                                <th scope="col">Track</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                speakerSessions.length > 0 ?
                                                    speakerSessions.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{x.Title}</td>
                                                                <td>
                                                                    <Link to={`/profile?q=${x.Email + "&" + x.EntryID}`}>
                                                                        {x.FirstName + " " + x.MiddleName + " " + x.LastName}
                                                                    </Link>
                                                                </td>
                                                                <td>{x.Organization}</td>
                                                                <td>{x.Designation}</td>
                                                                <td>
                                                                    {/* {
                                                                        sessionList.length > 0 &&
                                                                        sessionList.filter(k => parseInt(k.EntryID) === parseInt(item.SessionID))[0].Title

                                                                    } */}
                                                                    {
                                                                        x.s_sess.length > 0 &&
                                                                        x.s_sess.map((z, ib) => {
                                                                            return (
                                                                                <ul key={ib}>
                                                                                    <li>
                                                                                        {
                                                                                            sessionList.length > 0 &&
                                                                                            sessionList.filter(k => parseInt(k.EntryID) === parseInt(z.SessionID))[0].Title
                                                                                        }

                                                                                        {
                                                                                            x.ss.length > 0 &&
                                                                                            x.ss.filter(l => l.SpeakerID === x.EntryID && l.SessionID === z.EntryID).map((item, index) => {
                                                                                                return (
                                                                                                    <ul key={index}>
                                                                                                        <li>
                                                                                                            {item.Title}
                                                                                                            <div>
                                                                                                                <strong><small>   
                                                                                                                    {formatAMPM(item.StartTime)} - {formatAMPM(item.EndTime)}
                                                                                                                || {formatDateAndTime(item.Date, "date")}</small></strong>
                                                                                                            </div>
                                                                                                        </li>

                                                                                                    </ul>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </li>

                                                                                </ul>
                                                                            )
                                                                        })
                                                                    }

                                                                    {
                                                                        x.ss.length > 0 ?
                                                                            x.ss.map((item, index) => {
                                                                                return (
                                                                                    <ul key={index}>
                                                                                        <li>
                                                                                            {
                                                                                                item.Title
                                                                                            }
                                                                                            <div>
                                                                                                <strong><small>  {formatAMPM(item.StartTime)} - {formatAMPM(item.EndTime)} || {formatDateAndTime(item.Date, "date")}</small></strong>
                                                                                            </div>
                                                                                        </li>

                                                                                    </ul>
                                                                                )
                                                                            })
                                                                            :
                                                                            ""
                                                                    }

                                                                    {
                                                                        <Link className="btn btn-sm btn-primary" to={`/uploads?q=${x.Email + "&" + x.EntryID}`}>View Uploads</Link>
                                                                    }

                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>No session</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageTracks);
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import male_avater from '../img/male_avater.jpg'
import { encryptData, serverLink } from "../url";

const Profile = (props) => {
  const param = window.location.href.split("&")[1];
  const [isloading, setIsLoading] = useState(true)
  const navigate = useNavigate();
  const [participant, setParticipant] = useState([]);
  const [formData, setFormData] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Organization: "",
    Title: "",
    Designation: "",
    Photo: "",
    CurrentPassword: "",
    NewPassword: "",
    RenewPassword: "",
    SpeakerID: param !== undefined ? param : props.loginData[0].EntryID
  })

  const onEdit = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  }

  const getData = async () => {
    // await axios.post(`${serverLink}dashboard/speakers-profile`, { SpeakerID: formData.SpeakerID })
    await axios.post(`${serverLink}participants/participant/fetch_2`, { SpeakerID: formData.SpeakerID })
      .then((res) => {
        if (res.data.length > 0) {
          setFormData({
            ...formData,
            FirstName: res.data[0].FirstName,
            MiddleName: res.data[0].MiddleName,
            LastName: res.data[0].LastName,
            Email: res.data[0].Email,
            Phone: res.data[0].Phone,
            Organization: res.data[0].Organization,
            Title: res.data[0].Title,
            Designation: res.data[0].Designation,
            Photo: res.data[0].Photo,
            sss: 'g'
          })
          setParticipant(res.data)
        }
        setIsLoading(false)
      })
      .catch(() => {

      })
  }

  const updateProfile = async (e) => {
    e.preventDefault();
    try {
      // await axios.patch(`${serverLink}dashboard/update-profile`, formData)

      await axios.patch(`${serverLink}participants/update-profile_2`, formData)
      .then((res) => {
          getData();
          showAlert("success", "Profile updated successfully", "success");
        })
        .catch(() => {
          showAlert("error", "Network error", "error")
        })
    } catch (e) {

    }
  }

  const ChangePassword = async (e) => {
    e.preventDefault();
    if (encryptData(formData.CurrentPassword) !== participant[0].Password) {
      showAlert("error", "Old Password Incorrect", "error")
      return false;
    }
    if (formData.NewPassword !== formData.RenewPassword) {
      showAlert("error", "password do not match", "error")
      return false;
    }
    try {
      // await axios.patch(`${serverLink}dashboard/update-password/${formData.SpeakerID}`, { Password: formData.NewPassword })
      await axios.patch(`${serverLink}participants/update-password_2/${formData.SpeakerID}`, { Password: encryptData(formData.NewPassword) })
      .then((res) => {
          if (res.data.message === "success") {
            showAlert("success", "password reset successful", "success")
              .then(() => {
                navigate('/dashboard')
              });
          }
        })
    } catch (e) {
      showAlert("error", "please try again", "error")
    }

  }

  const uploadPicture = async (e) => {
    let file = e.target.files[0];
    const dt_ = new FormData();
    dt_.append("Photo", file);
    dt_.append("SpeakerID", formData.SpeakerID);
    if (!file.type.includes("image/")) {
      toast.error("Only Images are allowed");
      return;
    } else {
      toast.info("please wait...");
      await axios.patch(`${serverLink}conference/dashboard/update-passport`, dt_).then((res) => {
        if (res.data.message === "success") {
          getData();
          toast.success("photo updated")
        } else {

        }
      })

    }
  }

  useEffect(() => {
    getData();
  }, [])

  return isloading ? (<Loader />) : (
    <>
      <PageTitle title={["Home", "Profile"]} />
      <section className="section profile">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}
                <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview" aria-selected="false" role="tab" tabIndex={-1}>Overview</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit" aria-selected="true" role="tab">Edit Profile</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password" aria-selected="false" role="tab" tabIndex={-1}>Change Password</button>
                  </li>
                </ul>
                <div className="tab-content pt-2">
                  {
                    participant.length > 0 &&
                    <div className="tab-pane fade profile-overview active show" id="profile-overview" role="tabpanel">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">About</h5>
                          <h5 className="card-title">Profile Details</h5>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 label ">Full Name</div>
                            <div className="col-lg-9 col-md-8">{ formData.FirstName + " " + formData.MiddleName + " " + formData.LastName}</div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 label">Organization</div>
                            <div className="col-lg-9 col-md-8">{formData.Organization}</div>
                          </div>
                          {/* <div className="row">
                            <div className="col-lg-3 col-md-4 label">Designation</div>
                            <div className="col-lg-9 col-md-8">{formData.Designation}</div>
                          </div> */}

                          <div className="row">
                            <div className="col-lg-3 col-md-4 label">Phone Number</div>
                            <div className="col-lg-9 col-md-8">{formData.Phone}</div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-md-4 label">Email</div>
                            <div className="col-lg-9 col-md-8">{formData.Email}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="tab-pane fade profile-edit pt-3 show" id="profile-edit" role="tabpanel">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Edit your profile</h5>
                        <div className="row mb-3 pb-3">
                          {/* <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label> */}
                          <div className="col-md-8 col-lg-9">
                            <div className="row col-md-12">
                              {/* <div className="col-md-6">
                                <img src={formData.Photo === "" || formData.Photo === null ? male_avater : `${serverLink}public/docs/${formData.Photo}`} alt="Profile" />
                                <div className="pt-2">
                                  <span className="btn btn-primary btn-sm" title="Upload new profile image">
                                    <input type={"file"} accept="image/*" onChange={uploadPicture} style={{ display: 'hidden' }} />
                                    <i className="bi bi-upload" />
                                  </span>
                                </div>
                              </div> */}
                              {/* <div className="col-md-6">
                                  <textarea rows={5} onChange={onEdit} id="Profile" className="form-control" value={formData.Profile} />

                                </div> */}
                            </div>
                          </div>
                        </div>
                        <form className="row g-3" onSubmit={updateProfile}>
                          {/* <div className="row mb-3">
                            <label htmlFor="Title" className="col-md-4 col-lg-3 col-form-label">Title</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="Title" value={formData.Title} required onChange={onEdit} />
                            </div>
                          </div> */}
                          <div className="row mb-3">
                            <label htmlFor="FirstName" className="col-md-4 col-lg-3 col-form-label">First Name</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="FirstName" value={formData.FirstName} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="MiddleName" className="col-md-4 col-lg-3 col-form-label">Middle Name</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="MiddleName" value={formData.MiddleName} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="LastName" className="col-md-4 col-lg-3 col-form-label">Last Name</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="LastName" value={formData.LastName} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email Address</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="email" disabled className="form-control" id="Email" value={formData.Email} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">Phone Number</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="Phone" value={formData.Phone} required onChange={onEdit} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <label htmlFor="Organization" className="col-md-4 col-lg-3 col-form-label">Organization</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" disabled className="form-control" id="Organization" value={formData.Organization} required onChange={onEdit} />
                            </div>
                          </div>
                          {/* <div className="row mb-3">
                            <label htmlFor="Designation" className="col-md-4 col-lg-3 col-form-label">Designation</label>
                            <div className="col-md-8 col-lg-9">
                              <input type="text" className="form-control" id="Designation" value={formData.Designation} required onChange={onEdit} />
                            </div>
                          </div> */}

                          <div className="col-12">
                            <button className="btn btn-primary" type="submit">Update Profile</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade pt-3" id="profile-change-password" role="tabpanel">
                    <form onSubmit={ChangePassword}>
                      <div className="row mb-3">
                        <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="password" type="password" onChange={onEdit} className="form-control" id="CurrentPassword" />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="newpassword" type="password" className="form-control" id="NewPassword" onChange={onEdit} />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="renewpassword" type="password" className="form-control" id="RenewPassword" onChange={onEdit} />
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">Change Password</button>
                      </div>
                    </form>{/* End Change Password Form */}
                  </div>
                </div>{/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(Profile);

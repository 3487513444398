import { UploadFile } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import {Route, Routes} from 'react-router-dom'
import AddParticipant from "../admin/add-participant";
import ManageCommitee from "../admin/manage-commitee";
import ManageCommiteeMembers from "../admin/manage-commitee-members";
import ManageEvents from "../admin/manage-events";
import ManageParticipants from "../admin/manage-participants";
import ManagePartners from "../admin/manage-partners";
import ManageSessionDetails from "../admin/manage-session-details";
import ManageSessions from "../admin/manage-sessions";
import ManageSpeakers from "../admin/manage-speakers";
import ManageTracks from "../admin/manage-tracks";
import Login from "../auth/login";
import Header from "../common/header/header";
import SideBar from "../common/sidebar/sidebar";
import DashBoard from "../dashboard/dashboard";
import NotFound404 from "../error-pages/notfound404";
import Notices from "../profile/notices";
import Profile from "../profile/profile";
import DocumentUploads from "../profile/uploads";
import GenerateTag from "../admin/generate-tag/generate-tag";
import ManageReviewers from "../admin/manage-reviewers";
import ReviewerFeedBack from "../reviewer/review";
import Certificate from "../certificate/certificate";
import Papers from "../admin/papers/papers";
import Payment from "../admin/payments";


const PageRoutes = (props) => {
    const [toggle, setToggle] = useState("")

    const toggleSidebar = ()=>{
        const tg = toggle === "" ?  "toggle-sidebar" : ""
        setToggle(tg)
    }

    return (
        <div className={toggle} >
            <Header toggleSidebar={toggleSidebar} />
            <SideBar />
            <main id="main" className="main">
            <Routes>
                <Route  path={"/login"} element={<DashBoard/>}  />
                <Route  path={"/dashboard"} element={<DashBoard/>}  />
                <Route path="/profile" element={<Profile/>} />
                <Route path="/uploads" element={<DocumentUploads/>} />
                <Route path="/notice" element={<Notices/>} />
                <Route path="/certificate" element={<Certificate/>} />
                <Route path="/review" element={<ReviewerFeedBack/>} />

                {/* admin */}
                <Route path="/admin/reviewers" element={<ManageReviewers/>} />
                <Route path="/admin/events" element={<ManageEvents/>} />
                <Route path="/admin/committee" element={<ManageCommitee/>} />
                <Route path="/admin/committee/:slug" element={<ManageCommiteeMembers/>} />
                <Route path="/admin/papers" element={<Papers/>} />
                <Route path="/admin/session" element={<ManageSessions/>} />
                <Route path="/admin/session/:slug" element={<ManageSessionDetails/>} />
                <Route path="/admin/participant" element={<AddParticipant/>} />
                <Route path="/admin/participants" element={<ManageParticipants/>} />
                <Route path="/admin/speakers" element={<ManageSpeakers/>} />
                <Route path="/admin/partners" element={<ManagePartners/>} />
                <Route path="/admin/tracks" element={<ManageTracks/>} />
                <Route path="/admin/payments" element={<Payment/>} />

                <Route path="/admin/generate-tag" element={<GenerateTag/>} />

                {/* <Route  path={"/login"} element={<Login/>}  /> */}
                <Route path="*" element={<DashBoard/>} />
            </Routes>
            </main>
            
        </div>
    )
}
export default PageRoutes;

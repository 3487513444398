import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import PageTitle from "../common/pagetitle/pagetitle";
import { showAlert } from "../common/sweetalert/sweetalert";
import { serverLink, formatDate, formatDateAndTime, bucketUrl } from "../url";

const ManagePartners = (props) => {
    const [isloading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [partnersList, setpartnersList] = useState([]);
    const [formData, setFormData] = useState({
        PartnerName: "",
        ImagePath: "",
        Status: "",
        EntryID: "",
        InsertedBy: props.loginData[0].Email,
    })

    const getData = async () => {
        try {
            await axios.get(`${serverLink}partner/partner/list`).then((res) => {
                if (res.data.length > 0) {
                    setpartnersList(res.data)
                }
                setIsLoading(false)
            })

        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])

    const addPartners = async (e) => {
        e.preventDefault();
        setLoading(true);
        const dt_ = new FormData();
        dt_.append("PartnerName", formData.PartnerName);
        dt_.append("File", formData.ImagePath);
        dt_.append("Status", formData.Status);
        dt_.append("InsertedBy", formData.InsertedBy);

        try {
            await axios.post(`${serverLink}partner/add`, dt_).then((res) => {
                console.log(res.data)
                if (res.data.message === "success") {
                    setLoading(false);
                    getData();
                    Reset();
                    showAlert("success", "partner added successfully", "success");
                }
            })
        } catch (e) {
            setLoading(false)
            showAlert("error", "Network Error, try again", "error");
        }

    }
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
        if (e.target.id === "ImagePath") {
            setFormData({
                ...formData,
                ImagePath: e.target.files[0]
            })
        }

    }

    const Reset = () => {
        setFormData({
            ...formData,
            PartnerName: "",
            Image: "",
            Status: "",
            EntryID: "",
        })
    }
    return isloading === true ? (<Loader />) : (
        <>
            <PageTitle title={["Administartor", "Manage Partners"]} />
            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Add Partners</h5>
                                <form onSubmit={addPartners}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="PartnerName" className="col-md-12 col-lg-12 col-form-label">Partner Name</label>
                                                    <input type="text" className="form-control" id="PartnerName" value={formData.PartnerName} required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="ImagePath" className="col-md-12 col-lg-12 col-form-label">Image</label>
                                                    <input type="file" accept="images/*" className="form-control" id="ImagePath" required onChange={onEdit} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label htmlFor="Status" className="col-md-12 col-lg-12 col-form-label">Status</label>
                                                    <select className="form-control form-select" onChange={onEdit} id="Status">
                                                        <option value={""}>-select status-</option>
                                                        <option value={1}>Show</option>
                                                        <option value={0}>Hide</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <button className="btn btn-sm btn-primary" type="submit" disabled={loading ? true : false}>
                                                        {
                                                            loading === true &&
                                                            <>
                                                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />&emsp;
                                                            </>
                                                        }
                                                        {loading === true ? "Loading..." : "Add Partner"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="row align-items-top">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Partners List</h5>
                                <div className="table table-responsive-lg">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Image</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                partnersList.length > 0 ?
                                                    partnersList.map((x, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    {x.PartnerName}
                                                                </td>
                                                                <td>
                                                                    {/* <img width={"60px"} src={`${bucketUrl}${x.ImagePath}`} /> */}
                                                                    <img width={"60px"} src={x.ImagePath !== null ?
                                                                    `${serverLink}public/uploads/conference/partners/${x.ImagePath}` : ""}
                                                                        className="w-65px me-3" alt="" />
                                                                </td>
                                                                <td>{x.Chair}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={6}>No Commitees</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManagePartners);
import React from "react";
import { useState } from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import axios from 'axios'
import { currencyConverter, formatDate, formatDateAndTime, serverLink } from "../url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { useEffect } from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import { partc_mode_chart_data, partc_trend_chart_data, partc_type_chart_data, reg_trend_chart_data } from "./chart-data";


const DashBoard = (props) => {
    const [loading, setLoading] = useState(true);
    const [noticeList, setNoticeList] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [todaysSession, setTodaysSession] = useState([]);
    const color = ['success', 'danger', 'info', 'secondary', 'primary', 'warning']
    const [counts, SetCounts] = useState([]);
    const [RegChart, setRegChart] = useState([]);
    const [participationTypeChart, setParticipationTypeChart] = useState([]);
    const [participationModeChart, setParticipationModeChart] = useState([])



    const getData = async () => {
        try {
            await axios.get(`${serverLink}conference/dashboard/notice-board`)
                .then((res) => {
                    setNoticeList(res.data)
                })
                .catch(() => {
                    showAlert("Error", "Network error, please try again", "error")
                })
            await axios.get(`${serverLink}conference/dashboard/sessions`)
                .then((res) => {
                    if (res.data.length > 0) {
                        let yourDate = new Date();
                        let current_formatted_date = yourDate.toISOString().split('T')[0];

                        let todays_session = res.data.filter(x =>
                            formatDateAndTime(x.Date, "date") === formatDateAndTime(current_formatted_date.toString(), "date"));
                        setTodaysSession(todays_session)
                        setSessionList(res.data);
                    }
                })
                .catch(() => {
                    showAlert("Error", "Network error, please try again", "error")
                })
            await axios.get(`${serverLink}conference/dashboard/counts`)
                .then((res) => {
                    SetCounts(res.data);
                })
            await axios.get(`${serverLink}conference/dashboard/registration-trend`).then((res) => {
                let dt = reg_trend_chart_data(res.data);
                setRegChart([dt]);
            })

            await axios.get(`${serverLink}conference/dashboard/participation-mode-trend`).then((res) => {
                let dt = partc_mode_chart_data(res.data);
                setParticipationModeChart([dt]);

            })

            await axios.get(`${serverLink}conference/dashboard/participation-type-trend`).then((res) => {
                let dt = partc_type_chart_data(res.data);
                setParticipationTypeChart([dt]);

            })
        } catch (e) {

        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <PageTitle title={["Home", "Dashboard"]} />
            <section className="section dashboard">
                <div className="row">
                    {
                        props.loginData[0].Role === "Admin" &&
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Participants Registration Trend</h5>
                                    <div>
                                        {
                                            RegChart.length > 0 &&
                                            <div id="chart">
                                                <ReactApexChart
                                                    options={RegChart[0].options}
                                                    series={RegChart[0].series} type="line" width={'100%'} height={350} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Left side columns */}
                    <div className="col-lg-8">
                        <div className="row">
                            {/* Sales Card */}
                            {
                                props.loginData[0].Role === "Admin" &&

                                <div className="row">
                                    <div className="col-xxl-4 col-md-6">
                                        <div className="card info-card sales-card">
                                            <div className="card-body">
                                                <h5 className="card-title">Participants</h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                        <i className="bi bi-people" />
                                                    </div>
                                                    <div className="ps-3">
                                                        <h6>{counts.length > 0 ? counts[0].participants[0].participant : 0}</h6>
                                                        <span className="text-success small pt-1 fw-bold">Registered</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-md-6">
                                        <div className="card info-card revenue-card">
                                            <div className="card-body">
                                                <h5 className="card-title">Session</h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                        <i className="bi bi-calendar2-week" />
                                                    </div>
                                                    <div className="ps-3">
                                                        <h6>{counts.length > 0 ? counts[0].session[0].session : 0}</h6>
                                                        <span className="text-success small pt-1 fw-bold">Events</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6">
                                        <div className="card info-card customers-card">
                                            <div className="card-body">
                                                <h5 className="card-title">Committee</h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                        <i className="bi bi-people" />
                                                    </div>
                                                    <div className="ps-3">
                                                        <h6>{counts.length > 0 ? counts[0].committee[0].committee : 0}</h6>
                                                        <span className="text-danger small pt-1 fw-bold">committee</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6">
                                        <div className="card info-card revenue-card">
                                            <div className="card-body">
                                                <h5 className="card-title">Revenue</h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                        <i className="bi bi-currency-dollar" />
                                                    </div>
                                                    <div className="ps-3">
                                                        <h4>
                                                            <strong className="text-bold" style={{color:"#080F63"}} >
                                                                {counts.length > 0 ? currencyConverter(counts[0].payment[0].TotalAmount) : 0}
                                                            </strong>
                                                        </h4>
                                                        <span className="text-success small pt-1 fw-bold">Total Revenue</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-xxl-12 col-md-12">
                                        <div className="card">
                                            <div className="row card-body">
                                                <div className="col-md-6 pt-3">
                                                    <h6>Registration by Mode</h6>
                                                    {
                                                        participationModeChart.length > 0 &&
                                                        <div id="chart">
                                                            <ReactApexChart
                                                                options={participationModeChart[0].options}
                                                                series={participationModeChart[0].series}
                                                                type="donut" width={'100%'} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-md-6 pt-3">
                                                    <h6>Registration by Type</h6>

                                                    {
                                                        participationTypeChart.length > 0 &&
                                                        <div id="chart2">
                                                            <ReactApexChart
                                                                options={participationTypeChart[0].options}
                                                                series={participationTypeChart[0].series}
                                                                type="donut" width={'100%'} />
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="col-12" style={{ maxHeight: '600px', minHeight: "400px", overflowY: 'auto' }}>
                                <h4>Upcoming Sessions</h4>
                                {
                                    sessionList.length > 0 &&
                                    sessionList.map((x, y) => {
                                        return (
                                            <div className="card info-card customers-card" key={y}>
                                                <div className="card-body pt-4">
                                                    <div className="d-flex align-items-left">
                                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                            <i className="bi bi-people" />
                                                        </div>
                                                        <div className="ps-3" style={{ textAlign: 'left' }}>
                                                            <h6>{x.Title}</h6>
                                                            <div>
                                                                <span className="text-success text-bold small pt-1 fw-bold">Venue</span> <span className="text-muted small pt-2 ps-1 fw-bold">{x.Venue}</span>
                                                            </div>
                                                            <div>
                                                                <span className="text-success text-bold small pt-1 fw-bold">Date</span> <span className="text-muted small pt-2 ps-1 fw-bold">{formatDateAndTime(x.Date, "date")} : {x.StartTime} - {x.EndTime}</span>
                                                            </div>
                                                            <div>
                                                                <span className="text-success text-bold small pt-1 fw-bold">Anchor</span> <span className="text-muted small pt-2 ps-1 fw-bold">{x.Chair}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Notice Board</h5>
                                <div className="activity" style={{ maxHeight: '400px', minHeight: "400px", overflowY: 'auto', overflowX: 'hidden' }}>
                                    {
                                        noticeList.length > 0 ?
                                            noticeList.map((item, y) => {
                                                var color_ = color[Math.floor(Math.random() * color.length)];
                                                let data = new Date(item.InsertedDate)
                                                let date = formatDateAndTime(item.InsertedDate, "date")
                                                let hrs = data.getHours();
                                                let mins = data.getMinutes();
                                                if (hrs <= 9)
                                                    hrs = '0' + hrs
                                                if (mins < 10)
                                                    mins = '0' + mins
                                                const postTime = hrs + ':' + mins
                                                return (
                                                    <div className="activity-item d-flex" key={y} >
                                                        <div className="activite-label" >{postTime}</div>
                                                        <i className={`bi bi-circle-fill activity-badge text-${color_} align-self-start`} />
                                                        <div className="activity-content">
                                                            <div className="fw-bold text-dark">{item.Title}<br /></div>
                                                            <p>{item.Message}</p>
                                                            <div className="activite-label">{date}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div>
                                                <label className="alert alert-info">No notice yet</label>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};


export default connect(mapStateToProps, null)(DashBoard);

